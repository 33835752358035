
























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "@/app/shared/components/drag-&-drop/drag-&-drop-data-transfer";
import Node from "@/app/shared/components/tree/models/node.model";
import DropData from "@/app/shared/components/tree/models/node-drop-data.model";
import ContextMenuModel from "@/app/shared/components/tree/models/context-menu.model";
import TreeDropDataModel from "@/app/shared/components/tree/models/tree-drop.model";
import PreviousSearchResult from "@/app/shared/components/tree/search-result.class";

import TreeNode from "./tree-node.vue";

interface TreeDictionary {
  [key: number]: TreeNode;
}

@Component({
  components: {
    TreeNode,
  },
})
export default class Tree extends Vue {
  @Prop({ default: true, type: Boolean }) enableDrag!: boolean;
  @Prop({ default: false, type: Boolean }) enableDropOutside!: boolean;
  @Prop({ default: "", type: String }) url!: string;
  @Prop({ default: () => [], type: Array }) data!: Array<Node>;
  @Prop({ default: () => [], type: Array }) contextMenuItems!: Array<string>;
  @Prop({ default: "", type: String }) path!: string;
  @Prop({ default: true, type: Boolean }) enableSelect!: boolean;
  @Prop({ default: true, type: Boolean }) showEmptyText!: boolean;
  @Prop({ default: (node: Node): string => "", type: Function })
  nodeClassFunc!: (node: Node) => string;

  isTree = true;
  selectedNodeId: number | null = null;
  treeDictionary: TreeDictionary = {};

  /**
   * @param childId child node id
   * @param parentId parent node id
   * @description check if node(parentId) is parent for node(nodeId)
   */
  isNodeParentForNode(parentId: number, nodeId: number): boolean {
    let id: number | null = nodeId;
    while (id) {
      if (id == parentId) return true;
      id = this.treeDictionary[id].data.parentId;
    }
    return false;
  }

  @Emit()
  drop(state: State<Node, Node | DropData<Node>>): TreeDropDataModel {
    return {
      applyCallback: () => {
        const dragData = state.dragData;
        let dropData = state.dropData;

        // reset search results
        this.searchResult.reset();

        // if enable drop outside
        if (this.enableDropOutside && !(dragData.id in this.treeDictionary)) {
          if ("index" in dropData) {
            // if high level nodes
            if (dropData.data.parentId) {
              dragData.parentId = dropData.data.parentId;
              this.treeDictionary[dropData.data.parentId].data.children.splice(
                dropData.index,
                0,
                dragData
              );
            } else {
              this.data.splice(dropData.index, 0, dragData);
            }
          } else {
            dragData.parentId = dropData.id;
            dropData.children.push(dragData);
          }
          return;
        }

        const dropDataId =
          "index" in dropData ? dropData.data.parentId : dropData.id;

        // disable dragging parent inside himself
        if (dropDataId && this.isNodeParentForNode(dragData.id, dropDataId))
          return;

        //#region drop line
        if ("index" in dropData) {
          const { index: newPosition } = dropData as DropData<Node>;
          const { parentId: dropParentId = null } = dropData.data;
          const { parentId: dragParentId } = dragData;

          let removedNode: Node;
          let removeIndex: number;

          if (dragParentId) {
            // if drag node has parentId
            const dragParentData = this.treeDictionary[dragParentId].data;
            removeIndex = dragParentData.children.findIndex(
              (n) => n.id == dragData.id
            );

            // prevent changes if same or next drop line (high level nodes)
            if (
              (newPosition == removeIndex + 1 || newPosition == removeIndex) &&
              dragParentId == dropParentId
            )
              return;

            [removedNode] = dragParentData.children.splice(removeIndex, 1);
          } else {
            // if drag element node doesn't has parentId
            removeIndex = this.data.findIndex((n) => n.id == dragData.id);
            // prevent changes if same or next drop line (not high level nodes)
            if (
              (newPosition == removeIndex + 1 || newPosition == removeIndex) &&
              dropParentId === dragData.id
            )
              return;
            [removedNode] = this.data.splice(removeIndex, 1);
          }

          let position;

          if (removedNode.parentId === dropParentId) {
            position =
              removeIndex > newPosition ? newPosition : newPosition - 1;
          } else {
            position = newPosition;
          }

          removedNode.parentId = dropParentId; // set parentId

          // put removed node to a new place
          if (dropParentId) {
            this.treeDictionary[dropParentId].data.children.splice(
              position,
              0,
              removedNode
            );
          } else this.data.splice(position, 0, removedNode);
          return;
        }
        //#endregion drop line

        if (
          dragData.id === dropData.id || // if same node
          dragData.parentId === dropData.id || // if move node to same parent
          !dropData.children // if children empty
        )
          return;

        dropData.children.push(dragData);

        if (dragData.parentId) {
          const parent = this.treeDictionary[dragData.parentId];
          const index = parent.data.children.findIndex(
            (el) => el.id === dragData.id
          );
          parent.data.children.splice(index, 1);
        } else {
          const index = this.data.findIndex((el) => el.id === dragData.id);
          this.data.splice(index, 1);
        }

        dragData.parentId = dropData.id;
      },
      state,
    };
  }

  @Emit()
  contextMenuClick(params: ContextMenuModel): ContextMenuModel {
    return params;
  }

  openAndSelectNode(id: number): void {
    this.selectNode(id);
    this.openNode(id);
    this.$nextTick(() =>
      this.treeDictionary[id]?.$el.scrollIntoView({ behavior: "smooth" })
    );
  }
  selectNode(id: number | null): void {
    if (this.enableSelect) this.selectedNodeId = id;
  }
  openNode(id: number): void {
    if (!this.treeDictionary[id]) return;
    let { parentId } = this.treeDictionary[id].data;

    while (parentId) {
      this.treeDictionary[parentId].toggleNode();
      parentId = this.treeDictionary[parentId].data.parentId;
    }
  }

  private searchResult: PreviousSearchResult = new PreviousSearchResult();
  private nestedSearch(value: string, data: Array<Node>): number | null {
    for (let i = 0, { length } = data; i < length; i++) {
      const treeNodeData = (this.treeDictionary[data[i].id] as TreeNode).data;
      if (
        treeNodeData.documentName.toLowerCase().includes(value) &&
        !this.searchResult.hasId(treeNodeData.id)
      ) {
        this.searchResult.addId(treeNodeData.id);
        this.searchResult.setValue(value);
        this.openAndSelectNode(treeNodeData.id);
        return treeNodeData.id;
      }
      if (data[i].children.length) {
        const nodeId = this.nestedSearch(value, data[i].children);
        if (nodeId) return nodeId;
      }
    }
    return null;
  }
  search(value: null | string): number | null {
    if (!value) return this.selectedNodeId;
    const lowerValue = value.toLowerCase();
    // reset previous search result if another value
    if (!this.searchResult.sameValue(lowerValue)) this.searchResult.reset();
    const nodeId = this.nestedSearch(lowerValue, this.data);
    // if search result collect all possible ids select first one
    if (!nodeId && this.searchResult.ids.length) {
      const [firstId] = this.searchResult.ids;
      this.searchResult.reset();
      this.searchResult.addId(firstId);
      this.searchResult.setValue(lowerValue);
      this.openAndSelectNode(firstId);
    }
    return nodeId || this.selectedNodeId;
  }

  // save node to tree dictionary
  registerTreeNode(id: number, node: TreeNode): void {
    this.treeDictionary[id] = node;
  }
  // delete node from tree dictionary
  unregisterTreeNode(id: number): void {
    delete this.treeDictionary[id];
  }

  created(): void {
    this.$on("drop-tree", this.drop);
    this.$on("tree-context-menu-click", this.contextMenuClick);
  }

  beforeDestroy(): void {
    this.$off("drop-tree", this.drop);
    this.$off("tree-context-menu-click", this.contextMenuClick);
  }
}
