












































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Tree from "@/app/shared/components/tree/tree.vue";
import Drag from "@/app/shared/components/drag-&-drop/drag.vue";
import Drop from "@/app/shared/components/drag-&-drop/drop.vue";
import { State } from "@/app/shared/components/drag-&-drop/drag-&-drop-data-transfer";
import Node from "@/app/shared/components/tree/models/node.model";
import ContextMenuModel from "@/app/shared/components/tree/models/context-menu.model";
import DropData from "@/app/shared/components/tree/models/node-drop-data.model";

@Component({
  components: {
    Drag,
    Drop,
  },
  name: "TreeNode",
})
export default class TreeNode extends Vue {
  @Prop({ required: true }) data!: Node;
  @Prop({ default: 0 }) level!: number;
  @Prop({ type: Number }) index!: number;

  private tree?: Tree;
  private isOpen = false;

  private get path(): string {
    if (this.tree && this.tree?.path) {
      return this.tree?.path + this.data.id;
    }
    return "";
  }

  private get enableDrag(): boolean {
    return !!this.tree?.enableDrag;
  }

  private get selectedNodeId(): number | null | undefined {
    return this.tree?.selectedNodeId;
  }

  private get hasChildren(): boolean {
    return !!this.data?.children?.length;
  }

  private get nodeWrapperStyle(): { [key: string]: string } {
    if (this.isOpen && this.showLastDropLine)
      return { "padding-bottom": "5px" };
    return {};
  }

  private get nodeStyle(): { [key: string]: string } {
    return { "margin-left": this.level * 20 + "px" };
  }

  private get dropLineStyle(): { [key: string]: string } {
    const indent = this.level * 20 + 32;
    return {
      left: indent + "px",
      width: `calc(100% - ${indent}px)`,
    };
  }

  private get showLastDropLine(): boolean {
    const index = this.index + 1;
    if (this.data.parentId)
      return (
        this.tree?.treeDictionary[this.data.parentId].data.children.length ==
        index
      );
    return this.tree?.data.length == index;
  }

  private get contextMenuItems(): Array<string> {
    return this.tree?.contextMenuItems || [];
  }

  private enableDrop(
    state: State<Node, Node | DropData<Node>>,
    dropData: Node | DropData<Node>
  ): boolean {
    const { dragData } = state;

    // enable drop event if event triggered outside
    if (
      this.tree &&
      this.tree?.enableDropOutside &&
      !(dragData.id in this.tree.treeDictionary)
    ) {
      return true;
    }

    let enableDrop = true;

    const dropDataId =
      "index" in dropData ? dropData.data.parentId : dropData.id;

    // disable dragging parent inside himself
    if (dropDataId && this.tree?.isNodeParentForNode(dragData.id, dropDataId))
      enableDrop = false;

    // if drop event invoked on drop line
    if ("index" in dropData && this.tree) {
      const { index: newPosition } = dropData as DropData<Node>;
      const { parentId: dragParentId } = dragData;
      const { parentId: dropParentId = null } = dropData.data;

      // if dragging node has parentId
      if (dragParentId) {
        const dragParentData = this.tree?.treeDictionary[dragParentId].data;
        const removeIndex = dragParentData.children.findIndex(
          (n) => n.id == dragData.id
        );

        // prevent changes if same or next drop line (high level nodes)
        if (
          (newPosition == removeIndex + 1 || newPosition == removeIndex) &&
          dragParentId == dropParentId
        )
          enableDrop = false;
      } else {
        // if dragging node doesn't has parentId
        const index = this.tree?.data.findIndex((n) => n.id == dragData.id);
        if (
          (newPosition == index + 1 || newPosition == index) &&
          dropParentId === null
        )
          enableDrop = false;
      }
      // disable dragging to the same parent
    } else if (dragData.parentId === dropDataId) {
      enableDrop = false;
    }

    return enableDrop;
  }

  toggleNode(value = true): void {
    if (this.hasChildren)
      this.isOpen = typeof value === "boolean" ? value : !this.isOpen;
  }

  selectNode(): void {
    this.tree?.selectNode(this.data.id);
    this.toggleNode();
  }

  drop(data: State<Node, Node | DropData<Node>>): void {
    this.tree?.$emit("drop-tree", data);
  }

  contextMenuClick(value: string): void {
    let parent;
    if (this.data.parentId)
      parent = this.tree?.treeDictionary[this.data.parentId].data;

    this.tree?.$emit(
      "tree-context-menu-click",
      new ContextMenuModel(value, this.data, parent)
    );
  }

  created(): void {
    if ((this.$parent as Tree).isTree) {
      this.tree = this.$parent as Tree;
    } else this.tree = (this.$parent as TreeNode).tree;
    this.tree?.registerTreeNode(this.data.id, this);
  }

  beforeDestroy(): void {
    if (this.tree?.treeDictionary[this.data.id] === this)
      this.tree?.unregisterTreeNode(this.data.id);
  }
}
