export default class PreviousSearchResult {
  private _ids: Array<number> = [];
  private _value: string | null = null;

  get ids(): Array<number> {
    return this._ids;
  }

  addId(id: number): void {
    this._ids.push(id);
  }

  setValue(value: string): void {
    this._value = value;
  }

  hasId(id: number): boolean {
    return this._ids.includes(id);
  }

  sameValue(value: string): boolean {
    return this._value === value;
  }

  reset(): void {
    this._ids = [];
    this._value = null;
  }
}
