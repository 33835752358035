export class State<Drag, Drop> {
  public dragData!: Drag;
  public dropData!: Drop;
  public dropName = null;
}

export enum Keys {
  dragData = "dragData",
  dropData = "dropData",
  dropName = "dropName",
}

let state = new State();

export const store = {
  set(key: Keys, value: any): void {
    state[key] = value;
  },
  get(key: Keys): any {
    return state[key];
  },
  getState() {
    return state;
  },
  reset() {
    state = new State();
  },
};
