















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { HIGHLIGHT, HIDE_HIGHLIGHT } from "./drag-&-drop-event-bus/events";
import { store, Keys, State } from "./drag-&-drop-data-transfer";
import EventBus from "./drag-&-drop-event-bus";

@Component
export default class Drop extends Vue {
  @Prop() data: any;
  @Prop({ type: Boolean, default: true }) highlight!: boolean;
  @Prop({ type: [Boolean, Function], default: true }) enableDrop: any;
  @Prop({ type: String, default: "droppable" }) dropZone!: string;

  private showPreviewOverlay = false;
  private showDropOverlay = false;
  private enabled = false;

  isEnable(): boolean {
    if (typeof this.enableDrop === "function") {
      return this.enableDrop(store.getState(), this.data);
    }
    return this.enableDrop;
  }

  stylizeDroppableZone(styles: { [key: string]: string }): void {
    const keys = Object.keys(styles);
    keys.forEach((key: string) => {
      ((this.$el as HTMLElement).style as any)[key] = styles[key];
    });
  }

  dragEnter(): void {
    if (this.enabled) {
      store.set(Keys.dropData, this.data);
      this.$emit("mousedragenter", store.getState());
      // this.stylizeDroppableZone({
      //   border: "1px solid #2073CB",
      // });
      this.showPreviewOverlay = true;
    }
  }

  dragLeave(): void {
    if (this.enabled) {
      this.$emit("mousedragleave", store.getState());
      // this.stylizeDroppableZone({
      //   border: "1px solid transparent",
      // });
      // this.stylizeDroppableZone({ border: "1px solid grey" });
      this.showPreviewOverlay = false;
    }
  }

  dragOver(event: Event): void {
    event.preventDefault();
  }

  @Emit()
  drop(): State<any, any> {
    if (this.enabled) {
      this.$emit("mousedragend", store.getState());
      this.showPreviewOverlay = false;
    }
    return store.getState();
  }

  isAvailableDropZone(dropName: string | Array<string>): boolean {
    if (typeof dropName === "string") {
      return dropName === this.dropZone;
    }
    return dropName.some((name) => this.dropZone === name);
  }

  [HIGHLIGHT](dropName: string | Array<string>): void {
    setTimeout(() => {
      if (this.isAvailableDropZone(dropName)) {
        this.enabled = this.isEnable();
        if (!this.enabled) return;
        // this.stylizeDroppableZone({
        //   border: "1px solid grey",
        // });
        this.showDropOverlay = true;
      }
    }, 0);
  }

  [HIDE_HIGHLIGHT](): void {
    setTimeout(() => {
      // this.stylizeDroppableZone({
      //   border: "1px solid transparent",
      // });
      this.enabled = false;
      this.showPreviewOverlay = false;
      this.showDropOverlay = false;
    }, 0);
  }

  created(): void {
    EventBus.$on(HIGHLIGHT, this[HIGHLIGHT]);
    EventBus.$on(HIDE_HIGHLIGHT, this[HIDE_HIGHLIGHT]);
  }

  beforeDestroy(): void {
    EventBus.$off(HIGHLIGHT, this[HIGHLIGHT]);
    EventBus.$off(HIDE_HIGHLIGHT, this[HIDE_HIGHLIGHT]);
  }
}
