import { render, staticRenderFns } from "./drop.vue?vue&type=template&id=6bbf796e&scoped=true&"
import script from "./drop.vue?vue&type=script&lang=ts&"
export * from "./drop.vue?vue&type=script&lang=ts&"
import style0 from "./drop.vue?vue&type=style&index=0&id=6bbf796e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbf796e",
  null
  
)

export default component.exports