import Node from "./node.model";

export default class ContextMenuModel<T = Node> {
  value: string;
  node: T;
  parent?: T;

  constructor(value: string, data: T, parent?: T) {
    this.value = value;
    this.node = data;
    this.parent = parent;
  }
}
